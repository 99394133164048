import '../styles/buybutton.scss';

declare global {
    const APP_URL: string
}

// console.log('buybutton.js');

class HBBModal {
    static modalFrameClass: string = 'hbb-modal';
    modalFrame: HTMLIFrameElement;
    button: HTMLElement;
    index: number;
    modalURL: string

    constructor(button: HTMLElement, index: number) {
        // console.log('hbb constructor');
        this.button = button;
        this.index = index;
        // adds an attribute to mark this button as processed, to avoid infinite loop in the Mutation Observer
        this.button.setAttribute('data-processed', 'true');

        this.modalFrame = document.createElement('iframe');
        this.modalFrame.setAttribute('allow', 'geolocation');
        this.modalFrame.style.display = 'none';
        this.modalFrame.classList.add(HBBModal.modalFrameClass);

        this.modalURL = HBBManager.APP_URL + '/modal/';
        const ean = this.button.getAttribute('data-ean');
        const domain = this.button.getAttribute('data-domain');
        let defer = ((this.button.getAttribute('data-defer') || '1') === '1');
        const tab = this.button.getAttribute('data-tab') || null;
        const dmp = this.button.getAttribute('data-dmp') || null;
        const params = new URLSearchParams();
        if (ean && domain) {
            params.set('ean', ean);
            params.set('domain', domain);
            if (tab) {
                params.set('tab', tab);
            }
            if (dmp) {
                params.set('dmp', dmp);
            }
            // initial set of consentGeolocation. This may change once Didomi is loaded or when the user changes his consent.
            params.set('consentGeolocation', HBBManager.consentGeolocation ? '1' : '0');
            this.modalURL += '?' + params.toString();
            // console.log( this.modalURL);
            this.modalFrame.setAttribute(defer ? 'data-src' : 'src', this.modalURL);
            if (document.body) {
                document.body.appendChild(this.modalFrame);
                this.button.addEventListener('click', () => {
                    console.log('[buybutton.js] button clicked (defer: ' + defer + ')')
                    this.modalFrame.addEventListener('load', () => {
                        console.log('[buybutton.js] modalFrame load')
                        // now we set defer to false to enable instant load of later clicks on the button
                        defer = false;

                        this.postMessageOrigin();
                        const onMessage = (evt: MessageEvent) => {
                            if (evt.data === 'ready' && evt.origin === HBBManager.APP_URL) {
                                this.postMessageStart();
                                window.removeEventListener('message', onMessage);
                            }
                        };
                        window.addEventListener('message', onMessage);
                    });
                    if (defer) {
                        // Load iframe src
                        // update modalURL to get latest value of consentGeolocation
                        const url = new URL(this.modalFrame.getAttribute('data-src') as string);
                        const searchParams = url.searchParams;
                        searchParams.set('consentGeolocation', HBBManager.consentGeolocation ? '1' : '0');
                        this.modalFrame.removeAttribute('data-src');
                        console.log('[buybutton.js] start loading modalFrame')
                        this.modalFrame.setAttribute('src', url.toString());
                    } else {
                        this.postMessageOrigin();
                        this.postMessageStart();
                    }
                    this.modalFrame.classList.add('active');
                    // a dummy timout to allow background-color transition to be visible after toggling the display: block property. (see css)
                    setTimeout(() => {
                        this.modalFrame.classList.add('start');
                    }, 1);
                });
            }

            // Handle the hbb-open query string parameter
            const queryStringSearchParams = new URLSearchParams(location.search);
            const qsIndexMatch = queryStringSearchParams.get('hbb-open')?.match(/^button([0-9]+)$/);
            if (qsIndexMatch) {
                const qsIndexValue = qsIndexMatch[1];
                console.log('[buybutton.js] found hbb-open paramater with value ' + qsIndexValue);
                if (qsIndexValue && parseInt(qsIndexValue) === this.index) {
                    console.log('[buybutton.js] found button matching hbb-open value');
                    if (defer) {
                        console.log('[buybutton.js] trigger click event');
                        this.button.dispatchEvent(new Event('click'));
                    } else {
                        // TODO: find a better way to handle this case.
                        setTimeout(() => {
                            console.log('[buybutton.js] trigger click event after timeout');
                            this.button.dispatchEvent(new Event('click'));
                        }, 250);
                    }
                }
            }

            window.addEventListener('message', evt => {
                if (typeof evt.data.vueDetected === 'undefined') {
                    console.log('[buybutton.js] window received message from origin: ' + evt.origin);
                    if (evt.origin === HBBManager.APP_URL) {
                        console.log('[buybutton.js] message data: ' + evt.data, evt);
                        if (evt.data === 'closeModal') {
                            const iframes: NodeListOf<HTMLIFrameElement> = document.querySelectorAll('iframe.' + HBBModal.modalFrameClass);
                            iframes.forEach(iframe => {
                                if (iframe.contentWindow === evt.source) {
                                    iframe.classList.remove('active', 'start');
                                }
                            })
                        }
                    }
                }
            });
        }
    }

    postMessageOrigin() {
        // console.log(this.modalFrame);
        // console.log(this.modalFrame.contentWindow);
        if (this.modalFrame.contentWindow) {
            console.log('[buybutton.js] postMessage "origin:' + location.origin + '" to iframe with targetOrigin: ' + this.modalURL);
            this.modalFrame.contentWindow.postMessage('origin:' + location.origin, this.modalURL);

        }
    }

    postMessageStart() {
        if (this.modalFrame.contentWindow) {
            console.log('[buybutton.js] postMessage "start" to iframe with targetOrigin: ' + this.modalURL);
            this.modalFrame.contentWindow.postMessage('start', this.modalURL);
        }
    }
}

class HBBManager {
    static APP_URL: string = typeof APP_URL !== 'undefined' ? APP_URL : 'https://v2.libraires-hachette.com';
    static consentGeolocation: boolean = true;
    static hbbMO: MutationObserver = new MutationObserver((mutationsList) => {
        //console.log('MutationObserver called');
        //console.log(mutationsList);
        for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
                for (const addedNode of mutation.addedNodes) {
                    // Look for buy buttons inside the addedNode
                    if (addedNode.parentElement) {
                        HBBManager.lookForButtonsToCreate(addedNode.parentElement);
                    }
                }
            }
        }
    })

    constructor() {
        // check consent for geolocation (Didomi)
        (<any>window).didomiOnReady = (<any>window).didomiOnReady || [];
        (<any>window).didomiOnReady.push((/*Didomi: any*/) => {
            this.updateIframesSrcForConsent();
        });
        (<any>window).didomiEventListeners = (<any>window).didomiEventListeners || [];
        (<any>window).didomiEventListeners.push({
            event: 'consent.changed',
            listener: (/*context:any*/) => {
                this.updateIframesSrcForConsent();
            }
        });
        // load css
        const link = document.createElement('link');
        link.setAttribute('href', HBBManager.APP_URL + '/buybutton/buybutton.css');
        link.setAttribute('rel', 'stylesheet');
        document.head.appendChild(link);
        //
        if (document.body) {
            this.init();
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                this.init();
            });
        }
    }

    init() {
        HBBManager.hbbMO.observe(document.body, {childList: true, subtree: true});
        // check for existing buttons
        HBBManager.lookForButtonsToCreate(document.documentElement);
    }

    updateIframesSrcForConsent() {
        const iframes: NodeListOf<HTMLIFrameElement> = document.querySelectorAll('iframe.' + HBBModal.modalFrameClass);
        iframes.forEach(iframe => {
            const src = iframe.getAttribute('src') as string || iframe.getAttribute('data-src') as string;
            const url = new URL(src);
            const searchParams = url.searchParams;
            HBBManager.consentGeolocation = (<any>window).Didomi.getUserConsentStatusForPurpose('geolocation_data');
            searchParams.set('consentGeolocation', HBBManager.consentGeolocation ? '1' : '0');
            if (iframe.getAttribute('data-src')) {
                iframe.setAttribute('data-src', url.toString());
            } else if (iframe.getAttribute('src')) {
                iframe.setAttribute('src', url.toString());
            }
        });
    }

    static lookForButtonsToCreate(root: HTMLElement) {
        // get buttons which have not been processed yet, to avoid infinite loop in the Mutation Observer
        if (root) {
            const buttons = root.querySelectorAll('[data-ean]:not([data-processed])') as NodeListOf<HTMLElement>;
            buttons.forEach((button: HTMLElement, index: number) => {
                if (button.nodeName.toLowerCase() !== 'script') {
                    new HBBModal(button, index);
                }
            });
        }
    }
}

new HBBManager();
